import { ReduxState, RestaurantStatus } from './types';

export const initialState: Omit<ReduxState, 'common'> = {
  widget: {
    restaurant: undefined,
    reservation: undefined,
    showConfirmationPage: false,
    restaurantStatus: RestaurantStatus.PENDING,
  },
};
