import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Restaurant } from '../types';
import { RestaurantLogo } from './RestaurantLogo';

interface Props {
  restaurant: Restaurant;
}

export const RestaurantInfos = ({ restaurant }: Props) => {
  const theme = useTheme();
  const isXsOrSmaller = useMediaQuery(theme.breakpoints.down('xs'));

  const headerAlign = isXsOrSmaller ? 'center' : 'left';

  return (
    <Grid container spacing={2} justify="center">
      {restaurant.logoUrl !== null && (
        <Grid
          item
          xs={12}
          sm={4}
          style={{ textAlign: 'center', margin: 'auto' }}
        >
          <RestaurantLogo
            restaurantName={restaurant.name}
            logoUrl={restaurant.logoUrl}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={8} style={{ paddingLeft: '1rem' }}>
        <Typography variant="h6" align={headerAlign}>
          Tisch reservieren für {restaurant.name}
        </Typography>
        <Typography
          variant="body2"
          align="justify"
          style={{ whiteSpace: 'pre-line' }}
        >
          {restaurant.mainText}
        </Typography>
      </Grid>
    </Grid>
  );
};
