import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { CommonActions } from '@gts-common/client';
import { ReduxState, Reservation, Restaurant } from '../types';

export const GET_RESTAURANT_SUCCEEDED = 'GET_RESTAURANT_SUCCEEDED';
export const RESTAURANT_NOT_FOUND = 'RESTAURANT_NOT_FOUND';
export const RESERVATION_SUCCEEDED = 'RESERVATION_SUCCEEDED';
export const RESTAURANT_WIDGET_NOT_ACTIVE = 'RESTAURANT_WIDGET_NOT_ACTIVE';
export const LINK_INVALID = 'LINK_INVALID';

export interface GetRestaurantSucceededAction extends Action {
  type: typeof GET_RESTAURANT_SUCCEEDED;
  payload: Restaurant;
}

export interface RestaurantNotFoundAction extends Action {
  type: typeof RESTAURANT_NOT_FOUND;
}

export interface ReservationSucceededAction extends Action {
  type: typeof RESERVATION_SUCCEEDED;
  payload: Reservation;
}

export interface RestaurantWidgetNotActiveAction extends Action {
  type: typeof RESTAURANT_WIDGET_NOT_ACTIVE;
}

export interface LinkInvalidAction extends Action {
  type: typeof LINK_INVALID;
}

export type Actions =
  | GetRestaurantSucceededAction
  | RestaurantNotFoundAction
  | ReservationSucceededAction
  | RestaurantWidgetNotActiveAction
  | LinkInvalidAction
  | CommonActions;

export type Thunk = ThunkAction<void, ReduxState, unknown, Actions>;
