import {
  convertLocalDateStringToServerDateString,
  okFailedResponseBodies,
  WIDGET_RESERVE_PATH,
  WidgetAddReservationRequestBody,
} from '@gts-ft/utils';
import {
  getErrorModalMessage,
  sendErrorToServer,
  serverRequestFailed,
  serverRequestSucceeded,
  showError,
  startServerRequest,
} from '@gts-common/client';
import { serverComm, getWidgetOperationFailureReason } from '@gts-ft/ui';
import { Reservation } from '../types';

import {
  RESERVATION_SUCCEEDED,
  Thunk,
  ReservationSucceededAction,
} from './reduxActionTypes';
import { widgetInactive, widgetNotActiveModal } from './widgetNotActive';

function reservationSucceeded(
  reservation: Reservation,
): ReservationSucceededAction {
  return {
    type: RESERVATION_SUCCEEDED,
    payload: reservation,
  };
}

export function execAddReservation(reservation: Reservation): Thunk {
  return (dispatch) => {
    dispatch(startServerRequest());

    // TableSelect always returns Array but we need only one id or undefined here
    const tableId = reservation.tableIds[0];

    const reservationForServer: WidgetAddReservationRequestBody = {
      name: reservation.name.trim(),
      personsNo: reservation.personsNo,
      date: convertLocalDateStringToServerDateString(reservation.date),
      time: reservation.time,
      telephone: reservation.telephone.trim(),
      remark: reservation.remark.trim(),
      email: reservation.email.trim(),
      acceptedPrivacy: reservation.acceptedPrivacy,
      tableId,
    };

    serverComm
      .execPostRequest<Record<string, never>, WidgetAddReservationRequestBody>(
        `${WIDGET_RESERVE_PATH}/${window.location.search}`,
        reservationForServer,
      )
      .then(
        (resp) => {
          if (resp.succeeded) {
            dispatch(reservationSucceeded(reservation));
            dispatch(serverRequestSucceeded());
          } else {
            if (
              resp.body.status ===
              okFailedResponseBodies.WIDGET_NOT_ACTIVE.status
            ) {
              dispatch(widgetInactive());
              dispatch(serverRequestFailed(widgetNotActiveModal));
            } else {
              dispatch(
                serverRequestFailed(getWidgetOperationFailureReason(resp)),
              );
            }
          }
        },
        (e: unknown) => {
          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
