import { connect } from 'react-redux';
import { App } from '../components/App';
import { ReduxState } from '../types';

const mapStateToProps = (state: ReduxState) => {
  return {
    restaurant: state.widget.restaurant,
    showConfirmationPage: state.widget.showConfirmationPage,
    restaurantStatus: state.widget.restaurantStatus,
  };
};

export const AppContainer = connect(mapStateToProps)(App);
