import { widgetReservationValidation } from '@gts-ft/ui';

import { format } from 'date-fns';
import { Formik } from 'formik';
import { CLIENT_DATE_FORMAT } from '@gts-common/client-server';
import { Restaurant, Reservation } from '../../types';
import { ReservationForm } from './ReservationForm/ReservationForm';

const initialValues = {
  personsNo: 2,
  name: '',
  email: '',
  date: format(new Date(), CLIENT_DATE_FORMAT),
  time: '',
  remark: '',
  telephone: '',
  acceptedPrivacy: false,
  tableIds: [],
};

interface Props {
  restaurant: Restaurant;
  execAddReservation: (reservation: Reservation) => void;
}

const AddReservation = ({ execAddReservation, restaurant }: Props) => (
  <Formik
    onSubmit={(values) => execAddReservation(values)}
    initialValues={initialValues}
    validationSchema={widgetReservationValidation}
  >
    {({ values }) => (
      <ReservationForm
        restaurant={restaurant}
        displayMaxPersonsInWidgetWarning={values.personsNo === -1}
      />
    )}
  </Formik>
);

export default AddReservation;
