import { useState } from 'react';

import { getFreeTimes, getTimeSelectOptions } from '@gts-ft/ui';
import { SelectInput, SelectInputProps } from '@gts-common/client';
import { Field, FieldProps } from 'formik';
import { addDays, format, getISODay, parse } from 'date-fns';
import {
  convertDecimalTimeToString,
  getWeekDayFromDecimal,
  toAbsoluteDecimal,
} from '@gts-ft/utils';
import { CLIENT_DATE_FORMAT } from '@gts-common/client-server';

interface PassableProps {
  date: string;
  personsNo: number;
  name: string;
  label: string;
  errorHandler: (e: unknown) => void;
  error?: string;
  serverPath: string;
  additionalQueryParams: {
    rid: string;
  };
  dateValid: boolean;
  personsNoValid: boolean;
  required: boolean;
}

interface Props extends PassableProps {
  showError: boolean;
  onChange: (
    value: Array<string | number> | string | number | undefined,
  ) => void;
  value: number | undefined;
}

interface State {
  isLoading: boolean;
  freeTimes: SelectInputProps['options'];
}

const TimeSelect = (props: Props) => {
  const [state, setState] = useState<State>({
    isLoading: false,
    freeTimes: [],
  });

  const updateFreeTimes = () => {
    const {
      date,
      personsNo,
      errorHandler,
      serverPath,
      additionalQueryParams,
    } = props;

    setState({
      isLoading: true,
      freeTimes: [],
    });

    void getFreeTimes({
      date,
      personsNo,
      errorHandler,
      serverPath,
      additionalQueryParams,
    }).then((freeTimes) => {
      setState({
        isLoading: false,
        freeTimes: getTimeSelectOptions(freeTimes),
      });
    });
  };

  const { dateValid, personsNoValid } = props;
  const isEnabled = dateValid && personsNoValid;
  const { freeTimes, isLoading } = state;

  return (
    <SelectInput
      onOpen={updateFreeTimes}
      isLoading={isLoading}
      disabled={!isEnabled}
      options={freeTimes}
      noResultsText="Keine Zeiten verfügbar"
      {...props}
    />
  );
};

export const TimeSelectField = (props: PassableProps) => (
  <Field name={props.name}>
    {(fieldProps: FieldProps) => {
      const { setFieldValue, values } = fieldProps.form;
      const selectedDate = parse(
        values['date'],
        CLIENT_DATE_FORMAT,
        new Date(),
      );

      // Subtract 1 so that current day starts from 0 instead of 1 so that
      // it works correctly with modulo 7 and the multiplication by 24 to get
      // the correct transformations from time string to decimal and back
      const currentDay = getISODay(selectedDate) - 1;

      const onChange = (value: unknown) => {
        if (values['time'] !== value && typeof value === 'number') {
          const newDay = getWeekDayFromDecimal(value);

          let toAdd = 0;
          while ((currentDay + toAdd) % 7 !== newDay) {
            toAdd += 1;
          }

          setFieldValue(props.name, convertDecimalTimeToString(value));
          setFieldValue(
            'date',
            format(addDays(selectedDate, toAdd), CLIENT_DATE_FORMAT),
          );
        }
      };

      const showError = Boolean(
        fieldProps.meta.touched && fieldProps.meta.error,
      );

      let convertedValue;
      if (fieldProps.field.value) {
        convertedValue = toAbsoluteDecimal(currentDay, fieldProps.field.value);
      }

      return (
        <TimeSelect
          {...props}
          {...fieldProps.field}
          value={convertedValue}
          error={fieldProps.meta.error}
          onChange={onChange}
          showError={showError}
        />
      );
    }}
  </Field>
);
