import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ThemeProvider, CssBaseline } from '@material-ui/core';

import {
  GlobalErrorCatcher,
  serviceTheme,
  setupGlobalErrorHandler,
} from '@gts-common/client';

import { serverComm } from '@gts-ft/ui';
import { getUrl, ServiceCode, UrlType } from '@gts-common/client-server';
import { store } from './store';
import { AppContainer } from './container/App';
import { getRestaurant } from './actions';
import logo from './images/logo.png';

setupGlobalErrorHandler(serverComm, store);

// Redux doesn't understand thunk actions
// Convince it that it is okay to use that here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
store.dispatch(getRestaurant() as any);

const Root = () => (
  <GlobalErrorCatcher
    serverComm={serverComm}
    headerTitle="free-table"
    logo={logo}
    domain={getUrl(ServiceCode.FREE_TABLE, UrlType.FRONT_PAGE)}
  >
    <CssBaseline />
    <Provider store={store}>
      <ThemeProvider theme={serviceTheme}>
        <AppContainer />
      </ThemeProvider>
    </Provider>
  </GlobalErrorCatcher>
);

ReactDOM.render(<Root />, document.getElementById('root'));
