import { TextField, TextareaField, CheckboxField } from '@gts-common/client';
import { Grid, Link, useMediaQuery, useTheme } from '@material-ui/core';

export const PersonalData = () => {
  const theme = useTheme();
  const isXsOrSmaller = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Grid container spacing={isXsOrSmaller ? 0 : 2}>
        <Grid item xs={12} sm={6}>
          <TextField label="Name" name="name" required={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Telefonnummer" name="telephone" required={true} />
        </Grid>
      </Grid>
      <Grid container spacing={isXsOrSmaller ? 0 : 2}>
        <Grid item xs={12}>
          <TextField label="E-Mail-Adresse" name="email" required={true} />
        </Grid>
        <Grid item xs={12}>
          <TextareaField label="Anmerkung (optional)" name="remark" />
        </Grid>
      </Grid>
      <CheckboxField type="checkbox" name="acceptedPrivacy" required={true}>
        Ich bin mit der Verarbeitung meiner Daten zur Bearbeitung und
        Durchführung meiner Reservierung sowie der Weiterleitung meiner Daten an
        das ausgewählte Restaurant einverstanden und akzeptiere die Bestimmungen
        zum{' '}
        <Link target="_blank" href="/datenschutz.html">
          Datenschutz
        </Link>
        . Die Einwilligung kann ich jederzeit mit Wirkung für die Zukunft
        widerrufen.
      </CheckboxField>
    </>
  );
};
