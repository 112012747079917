import { initialState } from '../initialState';
import {
  Actions,
  GET_RESTAURANT_SUCCEEDED,
  LINK_INVALID,
  RESERVATION_SUCCEEDED,
  RESTAURANT_NOT_FOUND,
  RESTAURANT_WIDGET_NOT_ACTIVE,
} from '../actions/reduxActionTypes';
import { ReduxState, RestaurantStatus } from '../types';

export function widgetReducer(
  state = initialState.widget,
  action: Actions,
): ReduxState['widget'] {
  switch (action.type) {
    case GET_RESTAURANT_SUCCEEDED:
      return {
        ...state,
        restaurant: action.payload,
        restaurantStatus: RestaurantStatus.ACTIVE,
      };

    case RESTAURANT_NOT_FOUND:
      return {
        ...state,
        restaurantStatus: RestaurantStatus.NOT_FOUND,
      };

    case RESERVATION_SUCCEEDED:
      return {
        ...state,
        reservation: action.payload,
        showConfirmationPage: true,
      };

    case RESTAURANT_WIDGET_NOT_ACTIVE:
      return {
        ...state,
        restaurantStatus: RestaurantStatus.NOT_ACTIVE,
      };

    case LINK_INVALID:
      return {
        ...state,
        restaurantStatus: RestaurantStatus.INVALID_LINK,
      };

    default:
      return state;
  }
}
