import { connect } from 'react-redux';

import { ConfirmationPage } from '../components/ConfirmationPage';
import { ReduxState } from '../types';

const mapStateToProps = (state: ReduxState) => {
  const restaurant = state.widget.restaurant;
  const reservation = state.widget.reservation;

  if (!restaurant) {
    throw new Error('Restaurant is not defined');
  }

  if (!reservation) {
    throw new Error('Reservation is not defined');
  }

  return {
    reservation,
    restaurantName: restaurant.name,
  };
};

export const ConfirmationPageContainer = connect(mapStateToProps)(
  ConfirmationPage,
);
