import {
  WIDGET_RESTAURANT_LOGO_PATH,
  WIDGET_RESTAURANT_PATH,
  WidgetGetRestaurantRequestQuery,
  WidgetGetRestaurantResponse,
} from '@gts-ft/utils';

import {
  getUrl,
  ServiceCode,
  ID_REGEX,
  UrlType,
  ServerResponseError,
  ServerResponseErrorType,
  IndexSignatureHack,
} from '@gts-common/client-server';

import { serverComm } from '@gts-ft/ui';
import {
  getErrorModalMessage,
  getQueryParameterByName,
  KnownModalMessage,
  MessageType,
  ModalType,
  serverRequestFailed,
  serverRequestSucceeded,
  startServerRequest,
  invalidLinkMessage,
  unknownLinkMessage,
  sendErrorToServer,
  showError,
} from '@gts-common/client';

import { Restaurant } from '../types';
import {
  GET_RESTAURANT_SUCCEEDED,
  GetRestaurantSucceededAction,
  LINK_INVALID,
  LinkInvalidAction,
  RESTAURANT_NOT_FOUND,
  RestaurantNotFoundAction,
  Thunk,
} from './reduxActionTypes';
import { getPersonSelection } from './helpers/getPersonSelection';
import { getDisabledDays } from './helpers/getDisabledDays';
import { widgetInactive, widgetNotActiveModal } from './widgetNotActive';

function getRestaurantSucceeded(
  data: Restaurant,
): GetRestaurantSucceededAction {
  return {
    type: GET_RESTAURANT_SUCCEEDED,
    payload: data,
  };
}

function restaurantNotFound(): RestaurantNotFoundAction {
  return {
    type: RESTAURANT_NOT_FOUND,
  };
}

function linkInvalid(): LinkInvalidAction {
  return {
    type: LINK_INVALID,
  };
}

function isTokenValid() {
  const value = getQueryParameterByName('rid');

  if (value === null) {
    return false;
  } else if (value === '') {
    return false;
  } else {
    return ID_REGEX.test(value);
  }
}

function getLogoUrl(logoFilename: string | null) {
  const serverUrl = getUrl(ServiceCode.FREE_TABLE, UrlType.API);
  if (logoFilename) {
    return `${serverUrl}${WIDGET_RESTAURANT_LOGO_PATH}/${logoFilename}${window.location.search}`;
  } else {
    return null;
  }
}

const invalidLinkModal: KnownModalMessage = {
  type: MessageType.MODAL,
  modalType: ModalType.ERROR_MODAL,
  title: 'Link ungültig',
  body: invalidLinkMessage,
};

const restaurantNotFoundModal: KnownModalMessage = {
  type: MessageType.MODAL,
  modalType: ModalType.ERROR_MODAL,
  title: 'Link unbekannt',
  body: unknownLinkMessage,
};

export function getRestaurant(): Thunk {
  return (dispatch) => {
    if (!isTokenValid()) {
      dispatch(linkInvalid());
      return dispatch(serverRequestFailed(invalidLinkModal));
    }

    dispatch(startServerRequest());

    // Token validity was checked above so it must be a string here
    const rid = getQueryParameterByName('rid') as string;

    const data: WidgetGetRestaurantRequestQuery = { rid };

    return serverComm
      .execGetRequest<
        WidgetGetRestaurantResponse,
        IndexSignatureHack<WidgetGetRestaurantRequestQuery>
      >(WIDGET_RESTAURANT_PATH, data)
      .then(
        (resp) => {
          if (resp.succeeded) {
            const { body } = resp;

            const restaurantElem = {
              logoUrl: getLogoUrl(body.logoFilename),
              name: body.settings.restaurantName,
              mainText: body.settings.widgetRestaurantInfoText,
              tableSelectionVisibleInWidget:
                body.settings.tableSelectionVisibleInWidget,
              reservationText: body.settings.reservationText,
              disabledDays: getDisabledDays(
                body.openingTimes,
                body.settings.lastDate,
              ),
              persons: getPersonSelection(body.settings.maxPersonsInWidget),
            };
            dispatch(getRestaurantSucceeded(restaurantElem));
            dispatch(serverRequestSucceeded());
          } else {
            dispatch(widgetInactive());
            dispatch(serverRequestFailed(widgetNotActiveModal));
          }
        },
        (e: unknown) => {
          if (e instanceof ServerResponseError) {
            if (e.type === ServerResponseErrorType.NOT_FOUND) {
              dispatch(restaurantNotFound());
              return dispatch(serverRequestFailed(restaurantNotFoundModal));
            }
          }

          dispatch(serverRequestFailed(getErrorModalMessage(e)));
        },
      )
      .catch((e: unknown) => {
        sendErrorToServer(serverComm, e);
        dispatch(showError(getErrorModalMessage(e)));
      });
  };
}
