import {
  getDateObjectFromServerDateString,
  WidgetRestaurantOpeningTimes,
} from '@gts-ft/utils';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { isClosedOnDate } from './isClosedOnDate';

export function getDisabledDays(
  openingTimes: WidgetRestaurantOpeningTimes,
  lastDate: string,
) {
  const today = new Date();
  const lastReservationDate = getDateObjectFromServerDateString(lastDate);
  const duration = differenceInCalendarDays(lastReservationDate, today);

  const { closedOnDays, closedOnDates } = openingTimes;
  const closedOnDaysList = [];

  for (let i = 0; i < duration + 1; i += 1) {
    const currentDate = addDays(today, i);
    if (isClosedOnDate(closedOnDays, currentDate)) {
      closedOnDaysList.push(currentDate);
    }
  }
  const closedOnDatesList = closedOnDaysList.concat(
    closedOnDates.map((d) => getDateObjectFromServerDateString(d)),
  );
  return [
    {
      after: lastReservationDate,
    },
    ...closedOnDatesList,
  ];
}
