import { WIDGET_TABLES_FREE_PATH, WIDGET_FREE_TIMES_PATH } from '@gts-ft/utils';
import { TableSelectField, ReservationFormData } from '@gts-ft/ui';
import {
  SelectField,
  DatepickerField,
  getQueryParameterByName,
} from '@gts-common/client';
import { Grid } from '@material-ui/core';

import { useFormikContext } from 'formik';
import { getFreeTablesErrorHandler } from '../../../helpers/getFreeTablesErrorHandler';
import { getFreeTimesErrorHandler } from '../../../helpers/getFreeTimesErrorHandler';
import { Restaurant } from '../../../types';
import { TimeSelectField } from './TimeSelect';

// The getRestaurant action makes sure that 'rid' is a defined
// query parameter
const queryParams = {
  rid: getQueryParameterByName('rid') as string,
};

// The widget doesn't have all tables and it also doesn't need them because
// it is not possible to load the widget and already have a selected table before loading the free tables
const tables = {};

interface Props {
  restaurant: Restaurant;
}

function getTableSelectFieldIsDisabled(values: ReservationFormData) {
  if (!values.personsNo) {
    return true;
  } else if (!values.date) {
    return true;
  } else if (!values.time) {
    return true;
  } else {
    return false;
  }
}

export const SelectTable = ({ restaurant }: Props) => {
  const { disabledDays, tableSelectionVisibleInWidget, persons } = restaurant;
  const numOfMdCols = tableSelectionVisibleInWidget ? 3 : 4;
  const {
    values,
    errors,
    setFieldValue,
  } = useFormikContext<ReservationFormData>();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={numOfMdCols}>
        <SelectField
          label="Personenanzahl"
          name="personsNo"
          options={persons}
          onChange={() => {
            setFieldValue('time', '');
          }}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={numOfMdCols}>
        <DatepickerField
          label="Datum"
          name="date"
          extraDisabledDays={disabledDays}
          onDayChange={() => {
            setFieldValue('time', '');
          }}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={numOfMdCols}>
        <TimeSelectField
          label="Uhrzeit"
          name="time"
          dateValid={!errors.date}
          personsNoValid={!errors.personsNo}
          date={values.date}
          personsNo={values.personsNo}
          errorHandler={getFreeTimesErrorHandler}
          serverPath={WIDGET_FREE_TIMES_PATH}
          additionalQueryParams={queryParams}
          required={true}
        />
      </Grid>
      {tableSelectionVisibleInWidget && (
        <Grid item xs={12} sm={6} md={3}>
          <TableSelectField
            label="Tisch (optional)"
            name="tableIds"
            tables={tables}
            date={values.date}
            time={values.time}
            personsNo={values.personsNo}
            errorHandler={getFreeTablesErrorHandler}
            serverPath={WIDGET_TABLES_FREE_PATH}
            disabled={getTableSelectFieldIsDisabled(values)}
            required={false}
            isMulti={false}
            additionalQueryParams={queryParams}
          />
        </Grid>
      )}
    </Grid>
  );
};
