import {
  Typography,
  makeStyles,
  Box,
  Paper,
  createStyles,
} from '@material-ui/core';
import { Button } from '@gts-common/client';

import { Form } from 'formik';
import { Restaurant } from '../../../types';
import { SelectTable } from './SelectTable';
import { PersonalData } from './PersonalData';

interface Props {
  restaurant: Restaurant;
  displayMaxPersonsInWidgetWarning: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
  }),
);

const formId = 'reservation-form';

export const ReservationForm = ({
  restaurant,
  displayMaxPersonsInWidgetWarning,
}: Props) => {
  const classes = useStyles();
  return (
    <Form noValidate={true} id={formId}>
      <Paper className={classes.paper}>
        <SelectTable restaurant={restaurant} />
        {displayMaxPersonsInWidgetWarning && (
          <Typography
            color="error"
            variant="h6"
            align="center"
            style={{ whiteSpace: 'pre-line' }}
          >
            {restaurant.reservationText}
          </Typography>
        )}
        {!displayMaxPersonsInWidgetWarning && (
          <>
            <PersonalData />
            <Box mt="1rem">
              <Button submitForForm={formId} label="Reservieren abschließen" />
            </Box>
          </>
        )}
      </Paper>
    </Form>
  );
};
