import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Container,
  makeStyles,
  Paper,
  useTheme,
  createStyles,
} from '@material-ui/core';
import { Reservation } from '../types';

interface Props {
  reservation: Reservation;
  restaurantName: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
  }),
);

export const ConfirmationPage = ({ reservation, restaurantName }: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Container maxWidth="xs" disableGutters={true}>
      <Paper className={classes.paper}>
        <Typography align="center" variant="h6">
          Bestätigung
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Restaurant:</TableCell>
              <TableCell>{restaurantName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name:</TableCell>
              <TableCell>{reservation.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Anzahl Personen:</TableCell>
              <TableCell>{reservation.personsNo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Datum:</TableCell>
              <TableCell>{reservation.date}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Uhrzeit:</TableCell>
              <TableCell>{reservation.time}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Telefonnummer:</TableCell>
              <TableCell>{reservation.telephone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Anmerkung:</TableCell>
              <TableCell>
                {reservation.remark ? reservation.remark : 'Keine'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};
