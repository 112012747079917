import { CommonState } from '@gts-common/client';

export interface Reservation {
  name: string;
  personsNo: number;
  date: string;
  time: string;
  telephone: string;
  remark: string;
  email: string;
  tableIds: Array<string>;
  acceptedPrivacy: boolean;
}

export interface Restaurant {
  logoUrl: string | null;
  name: string;
  disabledDays: Array<{ after: Date } | Date>;
  persons: Array<{ value: number; label: string }>;
  tableSelectionVisibleInWidget: boolean;
  reservationText: string;
  mainText: string;
}

export interface ReduxState {
  widget: {
    restaurant: Restaurant | undefined;
    reservation: Reservation | undefined;
    showConfirmationPage: boolean;
    restaurantStatus: RestaurantStatus;
  };
  common: CommonState;
}

export enum RestaurantStatus {
  PENDING = 'PENDING',
  INVALID_LINK = 'INVALID_LINK',
  NOT_FOUND = 'NOT_FOUND',
  NOT_ACTIVE = 'NOT_ACTIVE',
  ACTIVE = 'ACTIVE',
}
