import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  logoImage: {
    maxWidth: '100%',
    maxHeight: '200px',
  },
});

interface Props {
  logoUrl: string;
  restaurantName: string;
}

export const RestaurantLogo = ({ logoUrl, restaurantName }: Props) => {
  const classes = useStyles();

  return (
    <img
      src={logoUrl}
      alt={restaurantName + ' Logo'}
      className={classes.logoImage}
    />
  );
};
