import { KnownModalMessage, MessageType, ModalType } from '@gts-common/client';
import { widgetInactiveMessage } from '@gts-ft/ui';
import {
  RESTAURANT_WIDGET_NOT_ACTIVE,
  RestaurantWidgetNotActiveAction,
} from './reduxActionTypes';

export function widgetInactive(): RestaurantWidgetNotActiveAction {
  return {
    type: RESTAURANT_WIDGET_NOT_ACTIVE,
  };
}

export const widgetNotActiveModal: KnownModalMessage = {
  type: MessageType.MODAL,
  modalType: ModalType.ERROR_MODAL,
  title: 'Reservierungsformular inaktiv',
  body: widgetInactiveMessage,
};
