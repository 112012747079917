import {
  Footer,
  MessagesContainer,
  SimpleHeader,
  WarningText,
  PleaseWaitText,
  InvalidLinkText,
  UnknownLinkText,
} from '@gts-common/client';

import { Box, Container } from '@material-ui/core';
import { widgetInactiveMessage } from '@gts-ft/ui';
import { getUrl, ServiceCode, UrlType } from '@gts-common/client-server';
import { Restaurant, RestaurantStatus } from '../types';
import logo from '../images/logo.png';
import { ConfirmationPageContainer } from '../container/ConfirmationPage';
import { AddReservationContainer } from '../container/AddReservation';
import { RestaurantInfos } from './RestaurantInfos';

interface Props {
  restaurant: Restaurant | undefined;
  showConfirmationPage: boolean;
  restaurantStatus: RestaurantStatus;
}

export const App = ({
  restaurant,
  showConfirmationPage,
  restaurantStatus,
}: Props) => {
  return (
    <div>
      <SimpleHeader logoSrc={logo} title="free-table" />
      <Container style={{ marginBottom: '80px' }}>
        {(function getContent() {
          if (restaurantStatus === RestaurantStatus.NOT_FOUND) {
            return <UnknownLinkText />;
          } else if (restaurantStatus === RestaurantStatus.INVALID_LINK) {
            return <InvalidLinkText />;
          } else if (restaurantStatus === RestaurantStatus.NOT_ACTIVE) {
            return <WarningText>{widgetInactiveMessage}</WarningText>;
          } else if (
            restaurant &&
            restaurantStatus === RestaurantStatus.ACTIVE
          ) {
            return (
              <>
                <Box mb="1rem">
                  <RestaurantInfos restaurant={restaurant} />
                </Box>
                {showConfirmationPage && <ConfirmationPageContainer />}
                {!showConfirmationPage && <AddReservationContainer />}
              </>
            );
          } else {
            return (
              <Box mt="2rem">
                <PleaseWaitText />
              </Box>
            );
          }
        })()}
      </Container>

      <Footer domain={getUrl(ServiceCode.FREE_TABLE, UrlType.FRONT_PAGE)} />
      <MessagesContainer />
    </div>
  );
};
