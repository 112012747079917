import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import { execAddReservation } from '../actions';
import AddReservation from '../components/Reservation/AddReservation';
import { ReduxState, Reservation } from '../types';
import { Actions } from '../actions/reduxActionTypes';

const mapStateToProps = (state: ReduxState) => {
  const restaurant = state.widget.restaurant;

  if (!restaurant) {
    throw new Error('Restaurant is not defined');
  }

  return {
    restaurant,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, undefined, Actions>,
) => ({
  execAddReservation(reservation: Reservation) {
    dispatch(execAddReservation(reservation));
  },
});

export const AddReservationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddReservation);
