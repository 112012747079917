export function getPersonSelection(maxPersonsInWidget: number) {
  const personsList = [];

  for (let count = 0; count < maxPersonsInWidget; count += 1) {
    const personCount = count + 1;
    if (count === 0) {
      personsList.push({
        value: personCount,
        label: `${personCount} Person`,
      });
    } else {
      personsList.push({
        value: personCount,
        label: `${personCount} Personen`,
      });
    }
  }

  personsList.push({
    value: -1,
    label: `${maxPersonsInWidget + 1}+ Personen`,
  });

  return personsList;
}
